.container {
  margin: auto;
  width: 90%;
  position: relative;
}

.catalog {
  display: flex;
  flex-direction: row;
}

h3 {
  margin-left: 0 !important;
}

.cart-block {
  width: 100%;
  overflow: hidden;
  box-shadow: 0 -4px 8px 0 rgba(0,0,0,.05);
  background: #fff;
  position: fixed;  
  left: 0;
  bottom: 0;
  z-index: 4;
}

.cart-block .cart-block-items {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background: #f3ae56;
  border-radius: 5px;
  height: 48px;
}

.cart-block .cart-block-items span {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.cart-block .cart-block-items span.total {
  background: rgba(255,255,255,.2);
  padding: 6px 10px;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 10px;
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.cart-button {
  margin: 10px;
}

.cart-button button {
  width: 100%;
}