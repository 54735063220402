header {
  border-bottom: 1px solid #dadada;
  top: 0;
  background: #fff;
  z-index: 2;
  padding: 10px 0;
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
}

header .inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

header .inner img.logo {
  height: 70px;
}

header .inner .right-side-header {
  display: flex;
  align-items: center;
}

header .inner .right-side-header img {
  border-radius: 50px;
}

header .inner .right-side-header .heart {
  border-radius: 0 !important;
  margin-right: 40px;
}