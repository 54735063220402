.cart {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #f8f8f8;
  z-index: 4;
  height: 100vh;
  top: 100%;
  transition: .5s;
}

.cart-open {
  top: 0;
  height: 100%;
}

.cart-empty {
  margin: 120px auto;
  text-align: center;
  width: 60%;
}

.cart-content {
  margin: 30px 20px 30px 20px;
}

.cart-content h3 {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: row;
  margin: 0 0 30px 0;
}

.cart-content p {
  font-size: 14px;
}

.cart-items ul {
  padding-left: 0px;
}

.cart-items li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;
  min-height: 40px;
}

.cart-items .name {
  width: 100%;
  margin-top: 4px;
  font-size: 16px;
  line-height: 18px;
}

.cart-items .name span {
  display: block;
  font-size: 14px;
  color: #bbb;
}

.cart-items .price {
  width: 90px;
  text-align: right;
  margin-top: 6px;
  white-space: nowrap;
}

.cart-cultery {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;
  padding: 10px 0;
}

.cart-cultery .cultery div {
  font-size: 13px;
  color: #888;
}

.cart-sum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;
  padding: 10px 0 0 0 ;
}

.cart-sum .delivery-time, .cart-sum .total-price {
  color: #bbb;
}

.cart-sum .delivery-time div, .cart-sum .total-price div {
  color: #17181f;
  font-size: 18px;
}

.cart-order-delivery {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;
  padding: 10px 0;
  font-size: 14px;
}