
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.products nav {
  display: flex;
  flex-direction: column;
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  top: 74px;
  background: #fff;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  width: 100%;
  padding-top: 20px;
  overflow: auto;
}

.products nav ul {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  padding: 0 10px;
  margin: 5px 0;
}

.products h3 {
  margin: 15px 0 0 25px;
}


.products h3 {
  margin: 15px 0 0 25px;
  font-size: 20px;
}

.products h3 span {
  margin-left: 6px;
  color: #bbb;
  font-size: 18px;
  font-weight: normal;
}

.products .description {
  color: #bbb;
  margin: 15px 0 0 25px;
  font-size: 14px;
}

.products .products-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.products .products-content:after {
  content: '';
  flex: auto;
} 

.products .loader {
  margin-top: 10vh;
}