.payment-info {
  margin: 70px auto 0;
  width: 100%;
}

.payment-info .container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.payment-info .wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.payment-info img {
  margin: 0;
  border-width: 0;
  padding:  0;
  width: 167px;
}

.payment-info h3 {
  margin: 30px 0 10px 0;
  font-size: 30px;
  margin-top: 30px !important;
}

.payment-info p {
  text-align: center;
  font-size: 16px;
  margin: 50px 0;
  margin-top: 30px !important;
}