.products .card {
  position: relative;
  display: flex;
  width: 50%; 
}

.products .card .item {
  display: flex;
  width: 80%;
  margin: 10px 5px;
  padding: 10px;
  flex-direction: column-reverse;
  position: relative;
  border-radius: 5px;
  justify-content: center;
  background: #fff;
  overflow: hidden;
  cursor: pointer;
  border: solid 1px #eee;
  transition: .4s;
}

.products .card .item:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
}

.products .card .item .info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1 0 auto;
}

.products .card .item .title {
  font-weight: normal;
  font-size: 16px;
  margin-top: 20px;
  line-height: 1.1;
  width: 100%;
  text-align: center;
}

.products .card .item .title:first-letter {
  text-transform: uppercase;
}

.products .card .item .title span {
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: #bbb;
  margin: 5px 0 0;
  white-space: nowrap;
}

.products .card .item .info p {
  color: #bbb;
}

.products .card .item .price {
  margin: 10px 0 0;
  padding: 10px 0;
  background-color: #f6f6f6;
  border-radius: 10px;
  font-size: 15px;
  text-align: center;
  white-space: nowrap;
}

.products .card .item .thumb {
  height: 110px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.products .card .item .thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.products .card .item p {
  margin-top: 5px;
  width: 100%;
}