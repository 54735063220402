.btn {
    padding: 15px 30px;
    font-weight: bold;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background: #f5e91f;
    cursor: pointer;
}

.btn-second {
    background: #f5f5f5;
    color: inherit;
    font-weight: normal;
}

.btn-second-margin {
    margin-left: 10px;
}

.btn-black {
    background: #2e2a21;
    color: #fff;
}

.btn-delete {
    background: #f5f5f5;
    color: #c00;
}

.counter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 120px;
    margin-left: 10px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 5px;
    background: #fff;
}

.counter .minus {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.counter .count {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 14px/1.4 Arial,sans-serif;
}

.counter .plus {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.counter img {
    width: 16px;
}

a, button {
    outline: 0;
    color: #17181f;
    text-decoration: none;
}

input {outline:none;}

.none {
    display: none;
}

@media (max-width: 480px) {
    iframe {
        margin-top: 75px;
    }
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #f5e91f; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
