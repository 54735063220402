.order-out {
  margin: 150px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.order-out-content {
  margin: 0 auto;
  width: 1220px;
  position: relative;
  padding: 0 40px;
}

.order-out-content h3 {
  margin: 30px 0 10px 0;
  font-size: 30px;
}

.order-out-content p {
  margin: 0;
  margin-bottom: 50px;
}