nav li {
  display: flex;
  white-space: nowrap;
  margin-right: 10px;
  height: 44px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .05);
  background: #fff;
  border-radius: 40px;
}

nav li p {
  display: block;
  padding: 0 20px 0 10px;
}

nav .span {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-left: 2px;
  justify-content: center;
  align-items: center;
}