.not-found {
  display: flex;
  margin: 0 auto;
  width: 1220px;
  position: relative;
  padding: 0 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.not-found img {
  margin: 0;
  border-width: 0;
  padding: 0;
}

.not-found p {
  text-align: center;
  font-size: 16px;
  margin: 50px 0;
}

.not-found a {
  padding: 15px 60px;
}