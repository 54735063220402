.products-order-wrap {
  display: flex;
  border-top: 1px solid #eee;
}

.products-order {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: flex-start;
}

.row {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row label {
  margin: 0 5px 5px 0;
  width: 25%;
}

.row-wide label {
  width: 100%;
}

.row-double label {
  width: 300px;
}

.row label span {
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}

.row label input {
  padding: 0;
  height: 30px;
  border-radius: 4px;
  border: 2px solid #ccc;
  width: 100%;
  box-sizing: border-box; 
  padding-left: 10px;
}

.row label button {
  margin-top: 10px;
  width: 200px;
  height: 30px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #f5e91f;
  color: #17181f;
  outline: none;
  border: none;
  cursor: pointer;
}

.row label input:focus {
  border: 2px solid #f3ae56;
}

.row-payment {
  margin: 20px 0;
}

.row-payment .row-content {
  margin: 10px 0;
  padding: 10px 20px;
  background: #f8f8f8;
  border-radius: 5px;
}

.row-payment label {
  display: flex;
  flex-direction: row;
  width: auto;
  margin: 0 20px 0 0;
}

.row-payment label input[type=radio] {
  display: block;
  margin: 2px 5px 0 0;
  width: auto;
  border: none;
  border-radius: 0;
}

.row-payment label span {
  display: block;
  margin: 0;
  padding: 0;
}

.row-payment label input[type=number] {
  padding: 8px 10px;
  border-radius: 4px;
  border: 2px solid #ccc;
  width: 100%;
  width: 90px;
  margin-left: 10px;
}

.row-payment label input[type=number]:focus {
  border: 2px solid #f3ae56;
}

.row-payment label.surrender {
  align-items: center;
}

.row-addresses {
  margin: 10px 0;
}

.row-addresses:first-child label {
  font-size: 16px;
  margin-bottom: 20px;
}

.row-addresses label.radio {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 24px;
  cursor: pointer;
}

.row-addresses label.radio input {
  position: absolute;
  margin: 15px -25px;
  width: 15px;
  height: 15px;
  border: 2px solid #f3ae56;
}

.row-addresses label.radio div {
  color: #aaa;
  font-size: 14px;
  font-weight: 200;
}

.controls-order {
  margin-top: 40px;
  margin-bottom: 40px;
}

.controls-order .btn {
  width: 340px;
}

.controls-order h3 {
  font-weight: normal;
}

.controls-order h3 span {
  display: block;
  font-size: 80%;
  font-weight: normal;
  margin-left: 0;
  color: #999;
}

.controls-order .btn.btn-title {
  position: relative;
}

.controls-order .btn.btn-title img {
  position: absolute;
  right: 15px;
}

.controls-order .btn.btn-title-disable {
  cursor: default;
  background: #ccc;
}

.row-wide input[name=promoCode][issuccess=true] {
  border-color: #aaa !important;
}

.row-wide input[name=promoCode][iserror=true] {
  border-color: #ff402b !important;
}

.order-overlay {
  position: absolute;
  left: 0;
  right: 400px;
  bottom: 80px;
  top: 0;
  background: #ffffff99;
  z-index: 100;
}

.row-address {
  position: relative;
}

.addresses-list-inner {
  position: absolute;
  margin-top: 2px;
  width: 744px;
  top: 60px;
  max-height: 200px;
  background: #fafafa;
  overflow: auto;
  border: 2px solid #ccc;
  border-radius: 4px;
  z-index: 2;
}

.addresses-list-inner::-webkit-scrollbar {
  background: transparent;
  width: 5px;
}

.addresses-list-inner::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #dcdfe7;
}

.addresses-list-inner ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.addresses-list-inner ul li {
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;

}

.addresses-list-inner ul li span {
  font-size: 11px;
  color: #777;
  display: block;
}

.addresses-list-inner ul li:hover {
  background: #fff;
}

.products-order .is-error {
  border: 2px solid #ff402b;
}

.row-address .error-hint {
  color: #ff402b;
  font-size: 90%;
  width: 100%;
  margin-top: 5px;
}

.row-wide input[name=promoCode][iserror=true] {
  color: #ff402b;
  font-size: 90%;
  width: 100%;
  margin-top: 5px;
}

.row-wide label .error-hint {
  color: #ff402b;
  font-size: 90%;
  width: 100%;
  margin-top: 5px;
}

.order-block {
  width: 100%;
  overflow: hidden;
  box-shadow: 0 -4px 8px 0 rgba(0,0,0,.05);
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 4;
}

.order-button {
  margin: 10px;
}

.order-button button {
  width: 100%;
}

.order-button button .price {
  position: absolute;
  padding: 5px 8px 4px;
  background: #ffffff99;
  border-radius: 3px;
  font-weight: normal;
  font-size: 95%;
  right: 20px;
  top: 20px;
}

.spacer {
  height: 50px;
}