.modal-wrap {
  position: fixed;
  left: 0;
  top: 10vh;
  right: 0;
  bottom: 0;
  width: 95%;
  margin: auto;
  z-index: 1001;
  animation: modal-wrap-animation 0.3s;
}

@keyframes modal-wrap-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal {
  display: flex;
  background: #fff;
  margin: 0 auto;
  border-radius: 8px;
  flex-direction: column;
  top: 50px;
}

.modal .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.modal .title img {
  cursor: pointer;
}

.modal .content {
  padding: 20px;
  border-top: solid 1px #f5f5f5;
  border-bottom: solid 1px #f5f5f5;
  background-color: #fafafa;
}

.modal .content p {
  margin: 6px 0;
}

.modal .content .name {
  font-size: 18px;
}

.modal .content .description {
  font-size: 14px;
  color: #bbb;
}

.modal .content .measure {
  font-size: 14px;
  color: #777;
}

.modal .controls {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.amount {
  align-self: center;
  margin-left: auto;
  text-align: right;
}

.amount span {
  font-size: 13px;
  color: #bbb;
}

.amount .total {
 font-size: 18px;
}

.amount .total .price {
  font-size: 100%;
  color: #17181f;
  margin-right: 3px;
}